var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "page-header-wrapper",
    [
      _c(
        "a-card",
        { attrs: { bordered: false } },
        [
          _c(
            "div",
            { staticClass: "table-page-search-wrapper" },
            [
              _c(
                "a-form",
                { attrs: { layout: "inline" } },
                [
                  _c(
                    "a-row",
                    { attrs: { gutter: 48 } },
                    [
                      _c(
                        "a-col",
                        { attrs: { md: 8, sm: 24 } },
                        [
                          _c(
                            "a-form-item",
                            { attrs: { label: "任务名称" } },
                            [
                              _c("a-input", {
                                attrs: {
                                  placeholder: "请输入任务名称",
                                  "allow-clear": "",
                                },
                                model: {
                                  value: _vm.queryParam.jobName,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.queryParam, "jobName", $$v)
                                  },
                                  expression: "queryParam.jobName",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "a-col",
                        { attrs: { md: 8, sm: 24 } },
                        [
                          _c(
                            "a-form-item",
                            { attrs: { label: "任务组名" } },
                            [
                              _c("a-input", {
                                attrs: {
                                  placeholder: "请选择任务组名",
                                  "allow-clear": "",
                                },
                                model: {
                                  value: _vm.queryParam.jobGroup,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.queryParam, "jobGroup", $$v)
                                  },
                                  expression: "queryParam.jobGroup",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm.advanced
                        ? [
                            _c(
                              "a-col",
                              { attrs: { md: 8, sm: 24 } },
                              [
                                _c(
                                  "a-form-item",
                                  { attrs: { label: "任务状态" } },
                                  [
                                    _c(
                                      "a-select",
                                      {
                                        staticStyle: { width: "100%" },
                                        attrs: {
                                          placeholder: "请选择任务状态",
                                          "allow-clear": "",
                                        },
                                        model: {
                                          value: _vm.queryParam.status,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.queryParam,
                                              "status",
                                              $$v
                                            )
                                          },
                                          expression: "queryParam.status",
                                        },
                                      },
                                      _vm._l(
                                        _vm.statusOptions,
                                        function (d, index) {
                                          return _c(
                                            "a-select-option",
                                            {
                                              key: index,
                                              attrs: { value: d.dictValue },
                                            },
                                            [_vm._v(_vm._s(d.dictLabel))]
                                          )
                                        }
                                      ),
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ]
                        : _vm._e(),
                      _c(
                        "a-col",
                        { attrs: { md: (!_vm.advanced && 8) || 24, sm: 24 } },
                        [
                          _c(
                            "span",
                            {
                              staticClass: "table-page-search-submitButtons",
                              style:
                                (_vm.advanced && {
                                  float: "right",
                                  overflow: "hidden",
                                }) ||
                                {},
                            },
                            [
                              _c(
                                "a-button",
                                {
                                  attrs: {
                                    type: "primary",
                                    id: "tableQueryBtn",
                                  },
                                  on: { click: _vm.handleQuery },
                                },
                                [
                                  _c("a-icon", { attrs: { type: "search" } }),
                                  _vm._v("查询"),
                                ],
                                1
                              ),
                              _c(
                                "a-button",
                                {
                                  staticStyle: { "margin-left": "8px" },
                                  on: { click: _vm.resetQuery },
                                },
                                [
                                  _c("a-icon", { attrs: { type: "redo" } }),
                                  _vm._v("重置"),
                                ],
                                1
                              ),
                              _c(
                                "a",
                                {
                                  staticStyle: { "margin-left": "8px" },
                                  on: { click: _vm.toggleAdvanced },
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(_vm.advanced ? "收起" : "展开") +
                                      " "
                                  ),
                                  _c("a-icon", {
                                    attrs: {
                                      type: _vm.advanced ? "up" : "down",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ]
                      ),
                    ],
                    2
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "a-toolbar",
            {
              attrs: { loading: _vm.loading, refresh: "", tid: "1" },
              on: { refreshQuery: _vm.getList },
            },
            [
              _c(
                "div",
                { staticClass: "table-operations" },
                [
                  _c(
                    "a-button",
                    {
                      directives: [
                        {
                          name: "hasPermi",
                          rawName: "v-hasPermi",
                          value: ["super:monitor:job:add"],
                          expression: "['super:monitor:job:add']",
                        },
                      ],
                      attrs: { type: "primary" },
                      on: {
                        click: function ($event) {
                          return _vm.$refs.createForm.handleAdd()
                        },
                      },
                    },
                    [
                      _c("a-icon", { attrs: { type: "plus" } }),
                      _vm._v("新增 "),
                    ],
                    1
                  ),
                  _c(
                    "a-button",
                    {
                      directives: [
                        {
                          name: "hasPermi",
                          rawName: "v-hasPermi",
                          value: ["super:monitor:job:edit"],
                          expression: "['super:monitor:job:edit']",
                        },
                      ],
                      attrs: { type: "primary", disabled: _vm.single },
                      on: {
                        click: function ($event) {
                          return _vm.$refs.createForm.handleUpdate(
                            undefined,
                            _vm.ids
                          )
                        },
                      },
                    },
                    [
                      _c("a-icon", { attrs: { type: "edit" } }),
                      _vm._v("修改 "),
                    ],
                    1
                  ),
                  _c(
                    "a-button",
                    {
                      directives: [
                        {
                          name: "hasPermi",
                          rawName: "v-hasPermi",
                          value: ["super:monitor:job:remove"],
                          expression: "['super:monitor:job:remove']",
                        },
                      ],
                      attrs: { type: "danger", disabled: _vm.multiple },
                      on: { click: _vm.handleDelete },
                    },
                    [
                      _c("a-icon", { attrs: { type: "delete" } }),
                      _vm._v("删除 "),
                    ],
                    1
                  ),
                  _c(
                    "a-button",
                    {
                      directives: [
                        {
                          name: "hasPermi",
                          rawName: "v-hasPermi",
                          value: ["super:monitor:job:export"],
                          expression: "['super:monitor:job:export']",
                        },
                      ],
                      attrs: { type: "primary" },
                      on: { click: _vm.handleExport },
                    },
                    [
                      _c("a-icon", { attrs: { type: "download" } }),
                      _vm._v("导出 "),
                    ],
                    1
                  ),
                  _c(
                    "a-button",
                    {
                      directives: [
                        {
                          name: "hasPermi",
                          rawName: "v-hasPermi",
                          value: ["super:monitor:job:query"],
                          expression: "['super:monitor:job:query']",
                        },
                      ],
                      attrs: { type: "dashed" },
                      on: { click: _vm.handleJobLog },
                    },
                    [
                      _c("a-icon", { attrs: { type: "snippets" } }),
                      _vm._v("日志 "),
                    ],
                    1
                  ),
                ],
                1
              ),
            ]
          ),
          _c("view-form", {
            ref: "viewForm",
            attrs: { jobGroupOptions: _vm.jobGroupOptions },
          }),
          _c("create-form", {
            ref: "createForm",
            attrs: {
              statusOptions: _vm.statusOptions,
              jobGroupOptions: _vm.jobGroupOptions,
            },
            on: { ok: _vm.getList },
          }),
          _c("a-table", {
            attrs: {
              loading: _vm.loading,
              size: _vm.tableSize,
              rowKey: "jobId",
              tid: "1",
              columns: _vm.columns,
              "data-source": _vm.list,
              "row-selection": {
                selectedRowKeys: _vm.selectedRowKeys,
                onChange: _vm.onSelectChange,
              },
              pagination: false,
            },
            scopedSlots: _vm._u([
              {
                key: "jobGroup",
                fn: function (text, record) {
                  return _c(
                    "span",
                    { attrs: { title: _vm.jobGroupFormat(record) } },
                    [_vm._v(" " + _vm._s(_vm.jobGroupFormat(record)) + " ")]
                  )
                },
              },
              {
                key: "status",
                fn: function (text, record) {
                  return _c(
                    "span",
                    {},
                    [
                      _c(
                        "a-popconfirm",
                        {
                          attrs: { "ok-text": "是", "cancel-text": "否" },
                          on: {
                            confirm: function ($event) {
                              return _vm.confirmHandleStatus(record)
                            },
                            cancel: function ($event) {
                              return _vm.cancelHandleStatus(record)
                            },
                          },
                        },
                        [
                          _c(
                            "span",
                            { attrs: { slot: "title" }, slot: "title" },
                            [
                              _vm._v("确认"),
                              _c("b", [
                                _vm._v(
                                  _vm._s(
                                    record.status === "1" ? "开启" : "关闭"
                                  )
                                ),
                              ]),
                              _vm._v(_vm._s(record.jobName) + "的任务吗?"),
                            ]
                          ),
                          _c("a-switch", {
                            attrs: {
                              "checked-children": "开",
                              "un-checked-children": "关",
                              checked: record.status == 0,
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                },
              },
              {
                key: "operation",
                fn: function (text, record) {
                  return _c(
                    "span",
                    {},
                    [
                      _c(
                        "a-popconfirm",
                        {
                          attrs: { "ok-text": "是", "cancel-text": "否" },
                          on: {
                            confirm: function ($event) {
                              return _vm.confirmHandleRun(record)
                            },
                            cancel: function ($event) {
                              return _vm.cancelHandleRun(record)
                            },
                          },
                        },
                        [
                          _c(
                            "span",
                            { attrs: { slot: "title" }, slot: "title" },
                            [
                              _vm._v(
                                "确认执行一次" +
                                  _vm._s(record.jobName) +
                                  "的任务吗?"
                              ),
                            ]
                          ),
                          _c(
                            "a",
                            {
                              directives: [
                                {
                                  name: "hasPermi",
                                  rawName: "v-hasPermi",
                                  value: ["super:monitor:job:changeStatus"],
                                  expression:
                                    "['super:monitor:job:changeStatus']",
                                },
                              ],
                            },
                            [
                              _c("a-icon", { attrs: { type: "caret-right" } }),
                              _vm._v(" 执行一次 "),
                            ],
                            1
                          ),
                        ]
                      ),
                      _c("a-divider", { attrs: { type: "vertical" } }),
                      _c(
                        "a",
                        {
                          directives: [
                            {
                              name: "hasPermi",
                              rawName: "v-hasPermi",
                              value: ["super:monitor:job:query"],
                              expression: "['super:monitor:job:query']",
                            },
                          ],
                          on: {
                            click: function ($event) {
                              return _vm.$refs.viewForm.handleView(record)
                            },
                          },
                        },
                        [
                          _c("a-icon", { attrs: { type: "eye" } }),
                          _vm._v("详细 "),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                },
              },
            ]),
          }),
          _c("a-pagination", {
            staticClass: "ant-table-pagination",
            attrs: {
              "show-size-changer": "",
              "show-quick-jumper": "",
              current: _vm.queryParam.pageNum,
              total: _vm.total,
              "page-size": _vm.queryParam.pageSize,
              showTotal: function (total) {
                return "共 " + total + " 条"
              },
            },
            on: {
              showSizeChange: _vm.onShowSizeChange,
              change: _vm.changeSize,
            },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }